import { environment } from '../../../../apps/frontend/src/environments/environment'
import { Injectable } from '@angular/core'
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { map, Observable } from 'rxjs'

@Injectable({
  providedIn: 'root'
})
export class ReportService {

  constructor(
    private http: HttpClient
  ) { }

  fetchDriverPdf(): Observable<Blob> {
    const url = `${environment.apiUrl}/reports/drivers`
    const headers = new HttpHeaders({
      Accept: 'application/pdf' // Specify that we accept a PDF response
    })

    return this.http.get(url, {
      headers: headers,
      responseType: 'blob'
    }).pipe(
      map(values => {
        return values
      })
    )
  }

  fetchEventsPDF(eventIds: number[]): Observable<Blob> {
    const url = `${environment.apiUrl}/reports/events/${eventIds.join(',')}`
    const headers = new HttpHeaders({
      Accept: 'application/pdf' // Specify that we accept a PDF response
    })

    return this.http.get(url, {
      headers: headers,
      responseType: 'blob'
    }).pipe(
      map(values => {
        return values
      })
    )
  }

  sendReceipt(eventId: number, emailAddressId?: number): Observable<number> {
    // TODO: display message on unknown email
    const url = `${environment.apiUrl}/reports/receipts/${eventId}/${emailAddressId ?? 0}`
    return this.http.get<number>(url).pipe(
      map(value => {
        return value
      })
    )
  }
}
